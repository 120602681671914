<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>{{ $t(`analytics['Product Orders']`) }}</b-card-title>
      <!--b-dropdown
        text="Last 7 Days"
        variant="transparent"
        class="chart-dropdown "
        right
        no-caret
        toggle-class="p-0"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in chartInfo.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown-->
    </b-card-header>

    <b-card-body>
      <template v-if="chartData.chartOptions && chartData.series">
        <!-- apex chart -->
        <vue-apex-charts
          type="radialBar"
          height="325"
          :options="chartData.chartOptions"
          :series="chartData.series"
          total="55"
        />
      </template>

      <!-- chart info -->
      <div
        v-for="(dat,key,index) in chartData.chartInfo"
        :key="key"
        class="d-flex justify-content-between"
        :class="index === Object.keys(chartData.chartInfo).length - 1 ? '':'mb-1'"
      >
        <div class="series-info d-flex align-items-center">
          <feather-icon
            icon="CircleIcon"
            size="16"
            :class="chartData.chartOptions.colors_[index]"
          />
          <span class="font-weight-bold text-capitalize ml-75">{{ $te(`common.${key.toLowerCase()}`)? $t(`common.${key.toLowerCase()}`) : key }}</span>
        </div>

        <span>{{ dat }}</span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, // BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    // BDropdown,
    // BDropdownItem,
    BCardBody,
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({ }),
    },
  },
  data() {
    return {
      chartInfo: {},
      productOrdersRadialBar: {
        // series: [70, 52, 26],
        chartOptions: {
          // labels: ['CreditCard', 'QRcode', 'FreeSale'],
          plotOptions: {
            radialBar: {
              size: 150,
              hollow: {
                size: '20%',
              },
              track: {
                strokeWidth: '100%',
                margin: 15,
              },
              dataLabels: {
                value: {
                  fontSize: '1rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  offsetY: 5,
                },
                total: {
                  show: false,
                  label: 'Total',
                  fontSize: '1.286rem',
                  colors: '#5e5873',
                  fontWeight: '500',
                  formatter(w) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    // console.log(w)
                    return `${w.config.series.reduce((a, b) => (a + b))}%`
                  },
                },
              },
            },
          },
          // colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
          stroke: {
            lineCap: 'round',
          },
          chart: {
            height: 355,
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
        },
      },
    }
  },
  created() {
    // this.$http.get('/card/card-analytics/product-orders').then(res => { this.chartInfo = res.data })
  },
}
</script>
