<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <!--b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>
      <b-col md="6">
        <apex-radial-bar-chart />
      </b-col-->

      <b-col
        xl="8"
        md="6"
      >
        <b-card>
          <b-card-header>
            <b-card-title>{{ $t('Analytics') }}</b-card-title>
            <ExtDatePicker
              :value.sync="rangePicker"
            />
          </b-card-header>
        </b-card>
        <b-card />
      </b-col>

      <b-col
        xl="4"
        md="6"
      >
        <statistics-filters
          :company-filter.sync="companyFilter"
          :company-options="companyOptions"

          :object-filter.sync="objectFilter"
          :object-options="objectOptions"

          :machine-filter.sync="machineFilter"
          :machine-options="machineOptions"
        />
        <!--ecommerce-medal :data="data.congratulations" /-->
      </b-col>

      <!--b-col md="12">
        <card-analytic-revenue-report :data="data.revenueReport" />
      </b-col-->

      <b-col md="6">
        <card-analytic-goal-overview :chart-data.sync="data.goalOverviewRadialBar" />
      </b-col>

      <!--b-col md="8">
        <card-analytic-revenue />
      </b-col-->
      <b-col md="6">
        <card-analytic-product-orders :chart-data.sync="data.productOrders" />
      </b-col>

      <!--b-col
        lg="6"
        md="12"
      >
        <analytics-congratulation :data="data.congratulations" />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          __v-if="data.subscribersGained"
          icon="UsersIcon"
          :statistic="kFormatter(data.subscribersGained.analyticsData.subscribers)"
          statistic-title="Subscribers Gained"
          :chart-data="data.subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          __v-if_="data.ordersRecevied"
          icon="PackageIcon"
          color="warning"
          :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
          statistic-title="Orders Received"
          :chart-data="data.ordersRecevied.series"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <analytics-avg-sessions :data="data.avgSessions" />
      </b-col>
      <b-col lg="6">
        <analytics-support-tracker :data="data.supportTracker" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <analytics-timeline :data="data.timeline" />
      </b-col>
      <b-col lg="4">
        <analytics-sales-radar-chart :data="data.salesChart" />
      </b-col>
      <b-col lg="4">
        <analytics-app-design :data="data.appDesign" />
      </b-col-->
    </b-row>

    <!--b-row>
      <b-col cols="12">
        <invoice-list />
      </b-col>
    </b-row-->
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle,
  // BCardText,
} from 'bootstrap-vue'

// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
// eslint-disable-next-line import/no-cycle
import { kFormatter } from '@core/utils/filter'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
// import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
// import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
// import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
// import AnalyticsTimeline from './AnalyticsTimeline.vue'
// import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
// import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
// import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
// import ApexRadialBarChart from './ApexRadialBarChart.vue'

// import CardAnalyticRevenueReport from './CardAnalyticRevenueReport.vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import ExtDatePicker from '@core/components/ext-date-picker/ExtDatePicker.vue'
import CardAnalyticGoalOverview from './CardAnalyticGoalOverview.vue'
// import CardAnalyticRevenue from './CardAnalyticRevenue.vue'
import CardAnalyticProductOrders from './CardAnalyticProductOrders.vue'
import StatisticsFilters from './StatisticsFilters.vue'

import analyticsStoreModule from './analyticsStoreModule'
// eslint-disable-next-line import/no-cycle
import useAnalyticsList from './useAnalyticsList'

export default {
  components: {
    ExtDatePicker,
    BRow,
    BCol,

    BCard,
    BCardHeader,
    BCardTitle,
    // BCardText,

    // AnalyticsCongratulation,
    // AnalyticsAvgSessions,
    // StatisticCardWithAreaChart,
    // AnalyticsSupportTracker,
    // AnalyticsTimeline,
    // AnalyticsSalesRadarChart,
    // AnalyticsAppDesign,
    // InvoiceList,

    // EcommerceGoalOverview,
    // ApexRadialBarChart,

    // CardAnalyticRevenueReport,
    CardAnalyticGoalOverview,
    // CardAnalyticRevenue,
    CardAnalyticProductOrders,

    StatisticsFilters,
  },
  setup() {
    const ECOMMERCE_APP_STORE_MODULE_NAME = 'app-analyt'

    // Register module
    if (!store.hasModule(ECOMMERCE_APP_STORE_MODULE_NAME)) store.registerModule(ECOMMERCE_APP_STORE_MODULE_NAME, analyticsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ECOMMERCE_APP_STORE_MODULE_NAME)) store.unregisterModule(ECOMMERCE_APP_STORE_MODULE_NAME)
    })

    const {
      // Extra Filters
      machineFilter,
      companyFilter,
      objectFilter,

      machineOptions,
      companyOptions,
      objectOptions,

      rangePicker,
      data,
      refetchData,

    } = useAnalyticsList()

    refetchData()

    return {
      objectFilter,
      companyFilter,
      machineFilter,

      rangePicker,

      objectOptions,
      machineOptions,
      companyOptions,
      data,

    }
  },
  methods: {
    kFormatter,
  },
}
</script>
