<template>
  <date-picker
    v-model="range"
    type="datetime"
    class="w-100 bg-transparent border-0 shadow-none"
    range
    value-type="YYYY-MM-DD HH:mm"
    format="YYYY-MM-DD HH:mm"
    :lang="$i18n.locale"
  />
</template>

<script>
// eslint-disable-next-line
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line
import moment from 'moment'
export default {

  props: {
    /**
         * Contains the currently selected value. Very similar to a
         * `value` attribute on an <input>. You can listen for changes
         * using 'change' event using v-on
         * @type {Object||String||null}
         */
    value: {
      type: Array,
      // type: String,
      default: () => ([moment().set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }).format('YYYY-MM-DD HH:mm').toString(), moment().set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }).add(1, 'd').format('YYYY-MM-DD HH:mm')
        .toString()]),
      // default: () => (moment().format('YYYY-MM-DD HH:mm').toString()),
    },

  },

  computed: {
    range: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('update:value', newValue)
      },
    },
  },

}

</script>

<style lang="scss">
.flatpickr-input {
  padding: 0.438rem 0.5rem;
}
</style>
