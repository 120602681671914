<template>
  <b-card no-body>
    <b-card-header>
      <h4 class="mb-0">
        {{ $t(`analytics['Goal Overview']`) }}
      </h4>
      <!--b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text-->
    </b-card-header>
    <!-- apex chart -->
    <template v-if="chartData.chartOptions && chartData.series">
      <vue-apex-charts
        ref="chart"
        type="radialBar"
        height="245"
        :options="chartData.chartOptions"
        :series="chartData.series"
      />
    </template>
    <b-row class="text-center mx-0">
      <b-col
        cols="6"
        class="border-top border-right d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ $t('analytics.Current') }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-50">
          {{ chartData.current }}
        </h3>
      </b-col>

      <b-col
        cols="6"
        class="border-top d-flex align-items-between flex-column py-1"
      >
        <b-card-text class="text-muted mb-0">
          {{ $t('analytics.Previous') }}
        </b-card-text>
        <h3 class="font-weight-bolder mb-0">
          {{ chartData.previous }}
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({ }),
    },
  },
  data() {
    return {
      goal_overview: {},
      goalOverviewRadialBar: {
        // series: [83],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: [this.chartData.current > this.chartData.previous ? '#51e5a8' : '#aa0000'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#5e5873',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [this.chartData.current > this.chartData.previous ? $themeColors.success : $themeColors.warning],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    }
  },
  created() {
    // this.$http.get('/card/card-analytics/goal-overview').then(res => { this.goal_overview = res.data })
  },
}
</script>
